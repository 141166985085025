import React from 'react';
import './banner.css';

export class Banner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <div>
                <div className='banner-container'
                    style={{
                        backgroundImage: `url(/${this.props.imgURL})`,
                        height: `${this.props.imgHeight}px`
                    }}
                >
                    {this.props.content}
                </div>
                <div className='banner-bottom-line'><div></div></div>
            </div>
        </>;
        return (
            html
        );
    }
}