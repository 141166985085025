import React from "react"
import "./contactuspage-content.css"

export class ContactUsPageContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      senderType: "business",
    }
  }

  componentDidMount() {
    const script = document.createElement("script")

    script.src = "https://webforms.pipedrive.com/f/loader"
    script.async = true

    document.body.appendChild(script)
  }

  handleSenderTypeChange = (e) => {
    const senderType = e.target.value
    this.setState({ senderType })
  }

  render() {
    const html = (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <br />
            <br />
            <br />
            <div className="d-flex justify-content-center">
              <img
                alt=""
                className="contactus-page-content-form-background"
                src={"/contact-us-content-form-background.png"}
              />
            </div>
            <br />
            <div className="contact-us-info-item-container black">
              <div className="contact-us-info-item-title">Email Address</div>
              <div className="contact-us-info-item-description">
                info@tiggbee.com
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="contact-us-info-item-container black">
              <div className="contact-us-info-item-title">Mailing Address</div>
              <div className="contact-us-info-item-description">
                22431 Antonio Pkwy B160-202
                <br />
                Rancho Santa Margarita, CA 92688
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="contactus-iframe-container-outer">
              <div className="contactus-iframe-container">
                <div
                  className="pipedriveWebForms"
                  data-pd-webforms="https://webforms.pipedrive.com/f/2XhQEOPxaZHKSj2sQOBLDSJ49PtOCm3q5ibNEL5QXoCui5QqjGcL0HhGtWMB1ssin"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    return html
  }
}
