import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Banner } from '../components/banner';
import { ContactUspageBannerContent } from '../components/contactuspage-banner-content';
import { ContactUsPageContent } from '../components/contactuspage-content';
import { TiggbeeContactUs } from '../components/tiggbee-contact-us';
// import contact_us_banner from '../images/contact-us-banner.png';

const ContactUsPage = () => (
    <Layout>
        <SEO title="Contact Us" />
        <div className='contact-us-banner'>
            <Banner
                imgURL='contact-us-banner.png'
                imgHeight={510}
                content={<ContactUspageBannerContent />}
            />
        </div>
        <ContactUsPageContent />
        <TiggbeeContactUs
            titleElement={<>Our Mission is to empower our students regardless of economic or geographic barriers with the hope and knowledge of the opportunities to be happy and successful individuals.</>}
            isHideButton={true}
        />
    </Layout>
)

export default ContactUsPage