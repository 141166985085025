import React from 'react';
import './banner.css';
// import business_page_banner_gradient_background from '../images/business-page-banner-gradient-background.svg';

export class ContactUspageBannerContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            info: [
                { title: 'Toll Free Number', description: <>(833) 316-2270 (M-F 9am-5pm PST)</> }
            ]
        }
    }

    setInfoContent = (title, description) => {
        const content = <>
            <div className='contact-us-info-item-container'>
                <div className='contact-us-info-item-title'>{title}</div>
                <div className='contact-us-info-item-description'>{description}</div>
            </div>
        </>;
        return content;
    }

    render() {
        const html = <>
            <div className='banner-content'>
                <div className='banner-content-background contact-us-content-background'
                    style={{
                        backgroundImage: `url(/banner-gradient-background-1.png)`
                    }}
                ></div>
                <div className='banner-title homepage-banner-title business-banner-title contact-us-banner-title'>
                    <div>Contact Us</div>
                    <div className='contact-us-banner-description'>We'd love to hear from you!</div>
                </div>
                <div className='row contact-us-banner-info-row'>
                    {
                        this.state.info.map((info, index) => 
                            <div className='col-md-6' key={index}>{ this.setInfoContent(info.title, info.description) }</div>
                        )
                    }
                </div>
            </div>
        </>;
        return (
            html
        );
    }
}